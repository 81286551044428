<template>
  <b-modal
    id="card-modal"
    :title="
      readonly
        ? 'عرض معلومات البطاقة'
        : form.id
        ? 'تحديث بيانات البطاقة'
        : 'أضافة بطاقة جديد'
    "
    shadow
    hide-footer
    :no-close-on-backdrop="true"
    @hidden="clearForm"
    size="lg"
    body-class="p-5"
  >
    <div class="px-3 py-2">
      <VForm v-slot="{ onValidate }">
        <VFileField
          v-model="form.image"
          label="صورة الغلاف"
          type=".jpg, .png"
          :disabled="readonly"
        ></VFileField>
        <b-row>
          <b-col>
            <VTextField
              v-model="form.name"
              label="أسم البطاقة"
              rules="required"
              :readonly="readonly"
            ></VTextField>
          </b-col>
          <b-col>
            <VSelectField
              v-model="form.type"
              :items="types"
              label="نوع البطاقة"
              rules="required"
              :readonly="readonly"
            ></VSelectField>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            ><VTextEditor
              v-model="form.advantages_and_conditions"
              label="المزايا والشروط"
              rules="required"
              :readonly="readonly"
            ></VTextEditor
          ></b-col>
          <b-col
            ><VTextEditor
              v-model="form.fees_and_commissions"
              label="الرسوم والعمولات"
              rules="required"
              :readonly="readonly"
            ></VTextEditor
          ></b-col>
        </b-row>

        <b-row v-if="!readonly">
          <b-col>
            <b-button
              :variant="form.id ? 'success' : 'info'"
              block
              @click="onValidate(action)"
              >{{ form.id ? "تحديث" : "أضافة" }}</b-button
            >
          </b-col>
          <b-col>
            <b-button
              variant="outline-info"
              block
              @click="$bvModal.hide('card-modal')"
              >الغاء</b-button
            >
          </b-col>
        </b-row>
      </VForm>
    </div>
  </b-modal>
</template>
<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";
export default {
  props: { readonly: Boolean, state: String },
  computed: {
    form: {
      get() {
        return this.$store.state.banks.credits.form;
      },
      set(value) {
        this.$store.commit("banks/credits/SET_CARD", value);
      },
    },
    types() {
      return this.$store.state.banks.credits.types;
    },
  },
  methods: {
    action() {
      var state = this.form.id ? "update" : "store";
      this.$store
        .dispatch(`banks/credits/${state}`)
        .then((response) => {
          successAlert();
          this.$bvModal.hide("card-modal");
          this.$store.commit(
            `banks/credits/${state.toUpperCase()}_CARD`,
            response.payload
          );
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    clearForm() {
      this.form = clearObject(this.form);
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss"></style>
