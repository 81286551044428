<template>
  <VContent>
    <template slot="right">
      <span>البطاقات المصرفية</span>
    </template>
    <template slot="left">
      <b-button variant="info" size="md" class="text-light" v-b-modal.card-modal
        >اضافة</b-button
      >
    </template>
    <template slot="content">
      <b-table
        :items="items"
        :fields="fields"
        :tbody-transition-props="transProps"
        tbody-tr-class="text-muted"
        responsive
        table-class="table-font h-100"
      >
        <template #cell(id)="data">
          {{ (page - 1) * 10 + data.index + 1 }}
        </template>
        <template v-slot:cell(image)="{ item }">
          <img :src="ImageURL(item)" width="150" height="80" />
        </template>
        <template v-slot:cell(type)="{ item }">
          <div class="p-2 rounded-pill green">{{ getType(item.type) }}</div>
        </template>
        <template v-slot:cell(created_at)="{ item }">{{
          new Date(item.created_at).toISOString().slice(0, 10)
        }}</template>
        <template v-slot:cell(actions)="{ item }">
          <b-dropdown size="lg" variant="link" no-caret>
            <template #button-content>
              <b-icon icon="three-dots" animation="cylon"></b-icon>
            </template>
            <b-dropdown-item @click="getByID(item.id, true)">
              <b-icon
                icon="file-earmark-medical"
                scale="0.5"
                class="ml-2"
              ></b-icon>
              عرض</b-dropdown-item
            >
            <b-dropdown-item @click="getByID(item.id, false)">
              <b-icon icon="pencil-square" scale="0.5" class="ml-2"></b-icon>
              تعديل</b-dropdown-item
            >
            <b-dropdown-item @click="remove(item.id)">
              <b-icon icon="archive" scale="0.5" class="ml-2"></b-icon>
              حذف</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
    </template>
    <template slot="modals">
      <CardModal :readonly="readonly" @close="readonly = false"></CardModal>
    </template>
    <template slot="bottom">
      <b-pagination
        v-model="page"
        :current-page="meta.current_page"
        :per-page="meta.per_page"
        :total-rows="meta.last_page * meta.per_page"
      ></b-pagination>
    </template>
  </VContent>
</template>

<script>
import CardModal from "./components/cardModal";
import {
  successAlert,
  errorAlert,
  showAlertWithConfirm,
  ImageURL,
} from "@/utils/helper";

export default {
  components: { CardModal },
  data() {
    return {
      page: 1,
      readonly: false,
      transProps: {
        name: "flip-list",
      },
      fields: [
        {
          key: "id",
          label: "أ",
          sortable: true,
        },
        {
          key: "image",
          label: "صورة البطاقة",
          sortable: true,
        },
        {
          key: "name",
          label: "أسم البطاقة",
          sortable: true,
        },
        {
          key: "type",
          label: "نوع البطاقة",
          sortable: true,
        },
        {
          key: "created_at",
          label: "تاريخ الأضافة",
          sortable: true,
        },
        {
          key: "actions",
          label: "العمليات",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.banks.credits.list;
    },
    meta() {
      return this.$store.state.banks.credits.meta;
    },
    types() {
      return this.$store.state.banks.credits.types;
    },
  },
  watch: {
    page() {
      this.getAll();
    },
  },
  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.$store.dispatch("banks/credits/get", this.page);
    },
    getByID(id, status) {
      this.$store
        .dispatch("banks/credits/show", id)
        .then(() => {
          this.readonly = status;
          this.$bvModal.show("card-modal");
        })
        .catch(() => errorAlert());
    },
    remove(id) {
      showAlertWithConfirm().then((response) => {
        if (response.isConfirmed) {
          this.$store
            .dispatch("banks/credits/delete", id)
            .then(() => {
              successAlert();
              this.$store.commit("banks/credits/DELETE_CARD", id);
            })
            .catch((error) =>
              errorAlert(
                error.response ? error.response.data.message : "فشل الحذف"
              )
            );
        }
      });
    },
    getType(type) {
      return this.types.find((x) => x.value === type).text;
    },
    ImageURL(item) {
      return item.image ? item.image : ImageURL("user.jpg");
    },
  },
};
</script>

<style lang="scss">
// td[aria-colindex="2"] {
//   text-align: right;
// }
// td[aria-colindex="3"] {
//   direction: ltr;
// }
</style>
